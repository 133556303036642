.container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
  background-color: white;
  position: relative;
}

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 0.5rem;
}

.head {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.left {
  grid-column: 1;
  margin-top: 2em;
  display: flex;
  flex-direction: column;
}

.left > span {
  font-size: 17px;
}

.left > :nth-child(2) {
  color: red;
}

.centre {
  grid-column: 2;
  display: flex;
  justify-content: center;
  font-size: 25px;
}

.centre > :nth-child(1) {
  font-family: "Source Sans 3", sans-serif;
  font-style: normal;
  /* font-weight: 300; */
  font-size: 30px;
}

.right {
  grid-column: 3;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.author {
  display: flex;
  justify-content: center;
}
.author > :nth-child(1) {
  font-size: 17px;
  font-weight: 500;
  color: grey;
}

.rightLow {
  display: flex;
}

.rightLow > span {
  color: grey;
  padding: 0 0.3rem;
}

.main {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
  /* margin-left: 1rem; */
}

.focus {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}

/* .firstPara{
    display: flex;
    flex-wrap: wrap;
} */

/* .firstPara>span{
    padding-left:0.35rem ;
} */

.holders {
  font-size: 22px;
  font-weight: 200;
}
.img {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 20rem;
  padding: 1rem;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.img > img {
  width: 80%;
  max-height: 100%;
  margin-right: 2rem;
}

.stats {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  width: 100%;
}



.charts{
    /* display: flex; */
    width: 100%;
}


.achieve{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 0;
}

.acLeft{
  display: flex;
  width: 50%;
  /* justify-content: center; */
  align-items: center;
  
}

.likes{
  display: flex;
  /* margin-left: 2rem; */
  justify-content: center;
  align-items: center;

}

.likes>:nth-child(1){
  margin-right: 0.1rem;
  cursor: pointer;
}
.views{
  display: flex;
  /* margin-left: 2rem; */
  align-items: center;
  padding: 0;
  justify-content: flex-end;
  margin-right: 3rem;

}

.views>:nth-child(1){
  padding: 0;
  margin-right: 0.3rem;
}

.acRight{
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.acRight>:nth-child(1){
  margin-right: 3rem;
  cursor: pointer;
}
.acRight>:nth-child(2){
     cursor: pointer;
}


.message{
  background-color: rgb(33, 33, 33);
  display: flex;
  flex-direction: column;
  position: absolute;
 width: 50%;
  bottom: 2.8%;
 
  right: 6.5%;
  /* padding: 15rem 0; */
  height: auto;
  padding: 1rem 0;
  animation: fadeIn 0.5s ease-out; /* Add this line for animation */
  z-index: 1000;
 
  border-radius: 18px;
  /* height: 35vh; */
  justify-content: space-between;
  height: 50%;
}



.containMessage{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px; /* Adjust width as needed */
     background-color: rgb(47, 47, 47); /* Match the background color */
     
    }
    &::-webkit-scrollbar-track {
      background: transparent; /* Change the track color to grey */
    }
  

}
.chatTruth{
  display: flex;
  width: 100%;
  height: 100%;
  padding: 3rem 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.chatTruth>span{
  color:grey;
  /* font-size: 19px; */
  margin-top: 1rem;
}

.web{
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  max-width: 100%;


}
.webTop{
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
  flex-wrap: wrap;
  width: 100%;
  /* height: auto; */
  /* border: 2px solid black; */
  
}

.webTop>span{
  
  border: 1px solid black;
  border-radius: 15px;
  border-radius: 18px;
  padding: 0.8rem;
  background-color: rgb(47, 47, 47);
  color: white;
  /* max-width: 50%; */
  max-width: 70%;
  margin-right: 0.8rem;
  font-weight: 300;


}

.webDown{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  margin-top: 0.8rem;
  
}

.webDown>span{
  color: white;
  background-color: rgb(47, 47, 47);
  border: 1px solid black;
  border-radius: 18px;
  padding: 0.8rem;
  max-width: 70%;
  border-radius: 18px;
  font-weight: 300;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.msgInput{
  display: flex;
  width: 99%;



 
}

.inpMessage{
  position: sticky;
  /* bottom: 10%; */
  display: flex;
 width: 100%;
 justify-content: flex-start;
 border: 2px solid black;
 margin: 0 0.6rem;
 background-color: rgb(4, 3, 3);
 border-radius: 15px;

}

.input{
  display: flex;
  width: 100%;
  padding: 0.5rem;

}

.input>textarea{
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  resize: none;
 min-height: 30px;

 /* overflow: hidden; */
}



.myButton{
  display: flex;
  flex-direction: column;
  padding: 0 0.4rem;
  justify-content: center;
  
}
.myButton>button{
  border-radius: 80px;
  padding: 0.4rem 0.8rem;
  background-color: rgb(187, 12, 12);
  color: white;
  border: 1px solid white;
  
}


.myButton>button:hover{
  cursor: pointer;
  background-color: grey;
}

.close{
  display: none;
}