.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;


}

.head{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  
}

.head>span{
    
    font-size: 34px;
    /* font-weight: 600; */
    font-family: "Merriweather", serif;
}
.mainCard{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.scroller{
     display: flex;
     width: 100%;
     justify-content:flex-end;

}

.scroll{
    margin-right: 0.3rem;
    border: 2px solid black;
    background-color: rgb(245, 243, 238);
    padding: 0.4rem;
    border-radius: 12px;
}

.scroll:hover{
    cursor: pointer;
    color: grey;
}


.card {
    display: flex;
    /* justify-content: center; */
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: auto;
    /* padding: 2rem; */
    /* padding-bottom: 1rem; */
    /* height: 100%; */
    gap: 1rem; /* Add gap to ensure there's space between cards */
    margin-left: 1rem;
    margin-top: 1rem;
}

.scroll:disabled{
    background-color: #cccccc;
    cursor: none;
    border: none;
    /* color: white; */

}

.card::-webkit-scrollbar { /* WebKit browsers */
    display: none;
  }

