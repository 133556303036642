.container{
    display: flex;
    flex-direction: column;
    height: 100%;
    /* padding: 1rem; */
    overflow: hidden;
    background-color: rgb(44, 44, 44) ;


}

.top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;

}

.left{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 3rem;
    
}

.increment{
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
}

.increment>:nth-child(1){
    font-size: 43px;
    color: white;
    font-weight: bold;
    font-family: "Lora", serif;
    font-weight: 400;
   
}
.increment>:nth-child(2){
    /* margin-top: 0.5rem; */
    padding-top: 0.8rem;
    padding-left: 0.4rem;
    font-size: 18px;
    color: grey;
    font-weight: 500;
    font-family: "Merriweather", serif;
  font-weight: 300;
  font-style: normal;
}

.posterior{
    padding-left: 0.6rem;
    display: flex;
    /* justify-content: flex-end; */
    
}

.posterior>.button{
    /* background-color: #7ece7e; */
    background-color:  #eaa77e;
    padding: 0.8rem;
    /* border: none; */
    border-radius: 16px;
    color: white;
    /* font-weight: bold; */
    font-size: 17px;
    cursor: pointer;
    text-decoration: none;
}

.posterior>.button:hover{
    color: grey;
}



.right{
    /* margin-right: 4rem; */
   width: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;

}

.image {
    /* width: 400px; /* Set a specific width and height to maintain the circular shape */
    /* height: 300px; Adjust as necessary */ 
    width: 80%; /* Set a specific width and height to maintain the circular shape */
    height: 80%; /* Adjust as necessary */
    border: 2px solid transparent;
    border-radius: 100%; /* Make the container circular */
    overflow: hidden; /* Ensure the image doesn't overflow the circular container */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image>img {
    width: 100%;
    height: 100%;
    
    object-fit: cover;
  }

.middle{
    display: flex;
   
}