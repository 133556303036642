.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color:white;
}

.main{
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}

.main>div{
    color: black;
    font-size: 23px;
    font-family: "Merriweather", serif;
}

.body{
    display: flex;
    flex-direction: column;
    width: 100%;

}

.cardMain{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 1rem;
height: 100%;
  
}

