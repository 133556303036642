.container {
    display: flex;
    flex-direction: column;
   padding:1rem;
    min-width: 20%; /* Ensure the cards have a minimum width */
    max-width: 20%; /* Set a max-width to ensure consistency */
    max-height: 22rem;
    flex: 0 0 auto; /* Prevent cards from shrinking or growing */
    margin-right: 1.5rem;
    border: 2px solid black;
    border-radius: 18px;
    align-items: center;
    /* background-color: rgb(41, 55, 84); */
    overflow: hidden;
    margin-bottom: 1rem;
    background-color: #f5f5f5;
}

.head{
    width: 100%;
    display: flex;
    height: 60%;
    /* max-height: 80%; */
    justify-content: center;
    overflow: hidden;
}

.head>img{
    width: 100%;
        min-height: 10rem;
        max-height: 100%;
        /* min-height: 100%; */
        object-fit: contain;
        object-fit: cover;
        border: 2px solid white;
        border-radius: 14px;
  
}
    .head>img:hover{
        cursor: pointer;
        transform: scale(1.05); /* Slightly zooms in the image */
  
}

.content{
    /* margin-top: 0.3rem; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    /* background-color: aqua; */
    /* color: white; */
    
    
    
}

/* .content:hover{
    cursor: pointer;
    color: grey;
} */

.writing{
    display: flex;
    justify-content: center;
}

.writing>.span{
    text-decoration: none;
    color: black;
    font-size: 18px;
}
.writing>.span:hover{
      color: grey;
      cursor: pointer;
}

.time{
    display: flex;
    justify-content: flex-end;
      width: 100%;
    
}

.views{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.eyes{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
}

.likes>:nth-child(1):hover{
    cursor: pointer;
}

.button{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 1.2rem;

}

.btn{
    padding: 0.3em;
    text-decoration: none;
    /* background-color: rgb(254, 144, 76); */
    background-color:black;
    border: 2px solid rgb(48,48,48);
    border-radius: 11px;
    color: white;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
}

.btn:hover{
    cursor: pointer;
    color: grey;
}