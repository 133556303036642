.layout{
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100vh;
  
  /* overflow: auto; */
}

.span{
    text-decoration: none;
    color:black;
     font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;

}

.span:hover{
    color: grey;
    cursor: pointer;
}

.button{
    text-decoration: none;
}



.header, .contain{
    grid-row: 1;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem;
    /* margin-bottom: 0.8rem; */

}

.left{
    display: flex;
}

.left>.logo,.span{
     margin-left: 1rem;
     font-size: 22px;
}

.logo>.span{
    color: #fe904c;
    font-size: 23px;
    margin-right: 1rem;
    margin-left: 0;
    font-weight: bold;
}

.right{
    display: flex;
}

.right>.filter{
    display: flex;
    padding: 0.2rem;

}


.filter>.input{
   margin-right: 0.4rem;
}

.filter>.button{
    /* background-color: rgb(65, 65, 65); */
    background-color: #fe904c;;
    border: none;
    border-radius: 14px;
    font-size: 17px;
    color: white;
    padding: 0.3rem 0.8rem;
    cursor: pointer;
}

.filter>.button:hover{
    color:#eaa77e ;
}

.filter>.input{
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 14px;
   color: grey;
   padding: 0.3rem;
   background-color: white;
}
.filter>.input>input{
border: none;
outline: none;
}
.filter>.input>:nth-child(2){
cursor: pointer;
}

div.clickers{
    display: flex;
    padding: 0 0.3rem;
    margin-left: 2rem;
}
.clickers>:nth-child(1){
    margin-right: 1rem;
}

.clickers>button{
    padding: 0.3rem 0.8rem;
    border: none;
    border-radius: 14px;
    font-size: 17px;
    /* background-color: rgb(65, 65, 65); */
    background-color: #fe904c;
    color: white;
    cursor: pointer;
}

.clickers>button:hover{
    color: #eaa77e;
}

.contain{
    background-color: rgb(48, 48, 48);
}

.spaner{
    margin-left: 1rem;
    font-size: 22px;
    text-decoration: none;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
}

.spaner:hover{
    color: grey;
}

.content{
    grid: 2;
}

.footer{
    grid: 3;
    display: flex;
    justify-content: space-between;
    background-color:rgb(48, 48, 48) ;
    padding: 1rem;
    
}

.extreme{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 2rem;
    /* padding: 1rem; */

}



.top{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  
}

.top>:nth-child(1){
    color: #fe904c;
    font-size: 22px;
}
.top>:nth-child(2){
    color: grey;
    font-size: 13px;
}


.copy{
      padding-bottom:0 ;
}
.copy>span{
    color: white;
    font-size: 16px;
}

.supreme{
    display: flex;
    flex-direction: column;   
    margin-right: 2rem;
    justify-content: space-between;
    
}

.right>span{
    color: #fe904c;
    /* font-size: 22px; */
}

.wing{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wing>span{
    color: grey;
    padding-top: 0.4rem;
    cursor: pointer;
}

.wing>span:hover{
    color: white;
}

.content{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;

}

.outlet{
    width: 98%;
    /* background-color:#beafaf  ; */
    background-color: rgb(34, 34, 34) ;
}